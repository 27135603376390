import { ThemeProvider } from "styled-components"
import { theme } from "theme/ThemeUI"
import { Switch } from "react-router-dom"
import MainLayout from "components/Theme/Layout/Main/MainLayout"
import MainLayoutShiperto from "overrides/theme/shiperto/base/components/Theme/Layout/Main/MainLayout"
import MainLayoutEntel from "overrides/theme/entel/base/components/Theme/Layout/Main/MainLayout"
import DashboardRouter from "routes/DashboardRouter"
import GlobalProvider from "context/providers/GlobalProvider"
import { OrderProvider } from "context/providers"
import { MultiClient, Env } from "classes"
// import Multiclient from "components/Core/Multiclient"

// Temporal solution: Static import to use Layout per client
const get_layout = (client: any) => {
  let Component: any = MainLayout
  if (client === Env.clients[4]) {
    Component = MainLayoutShiperto
  }
  if (client === Env.clients[6] || client === Env.clients[5]) {
    Component = MainLayoutEntel
  }
  return Component
}

const DashboarContainer = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const themeApp = _client && theme[_client as keyof typeof theme]
  const Layout = get_layout(_client)

  return (
    <GlobalProvider>
      <OrderProvider>
        <ThemeProvider theme={themeApp || theme.default}>
          <Switch>
            {/* <Multiclient path_override="components/Theme/Layout/Main/MainLayout" hasChildren={true}>
              <DashboardRouter />
            </Multiclient> */}
            <Layout>
              <DashboardRouter />
            </Layout>
          </Switch>
        </ThemeProvider>
      </OrderProvider>
    </GlobalProvider>
  )
}

export default DashboarContainer
