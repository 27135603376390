import { IMenu } from "interfaces/IMenu"
import CardOption from "overrides/theme/entel/base/components/Summary/SummaryOption/CardOption"

const SummaryOptions = ({
  summaryOptions,
  current_shipping_type,
}: {
  summaryOptions: IMenu[]
  current_shipping_type?: string
}) => {
  // console.log("summaryOptions", summaryOptions)
  return (
    <>
      {summaryOptions.map(({ optionCount, path, title, status }, index) => (
        <CardOption
          index={index}
          key={`${path}-${title}`}
          optionCount={optionCount}
          path={path}
          title={title}
          status={status}
          current_shipping_type={current_shipping_type}
        />
      ))}
    </>
  )
}

export default SummaryOptions
