import { useEnv } from "hooks"
import { CLIENTS_AUTH0 } from "overrides/theme/entel/base/helpers/auth0_vars"

interface IAuth0Credentials {
  clientId: string
  audience: string
  domain: string
}

const useAuth0Credentials = (): IAuth0Credentials => {
  const [_client, _env] = useEnv().multiclient.get_user_env()

  const credentials = {
    clientId: CLIENTS_AUTH0[_client][_env].clientId || "",
    audience: CLIENTS_AUTH0[_client][_env].audience || "",
    domain: CLIENTS_AUTH0[_client][_env].domain || "",
  }

  return { ...credentials }
}

export default useAuth0Credentials
