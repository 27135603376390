import { Route, Switch } from "react-router-dom"
import RouteListDefault from "theme/assets/Data/RouteList"
import RouteListWom from "overrides/theme/wom/base/routes/List/RouteList"
import RouteListSandbox from "overrides/theme/sandbox/base/Routes/List/RouteList"
import RouteListEntel from "overrides/theme/entel/Routes/List/RouteList"
// import RouteListEntel from "overrides/theme/entel_old/base/Routes/List/RouteList"
import RouteListShiperto from "overrides/theme/shiperto/Routes/List/RouteList"
import { Env } from "classes"
import { useEnv } from "hooks"

const DashboardRouter = () => {
  const [client] = useEnv().multiclient.get_user_env()

  const getClientConfig = (group: string = "") => {
    switch (group) {
      case Env.clients[1]:
        return RouteListWom
      case Env.clients[3]:
        return RouteListSandbox
      case Env.clients[4]:
        return RouteListShiperto
      case Env.clients[5]:
      case Env.clients[6]:
        return RouteListEntel
      default:
        return RouteListDefault
    }
  }

  let RouteList = getClientConfig(client)

  return (
    <Switch>
      {RouteList.map((route: any, index: number) => {
        const Component = route.component
        return (
          <Route key={index} path={route.path} exact={route.exact}>
            <Component {...route.props} />
          </Route>
        )
      })}
    </Switch>
  )
}

export default DashboardRouter
