import AppRouter from "routes/AppRouter"
import AuthProvider from "context/providers/AuthProvider"
import GlobalStyled from "theme/base/GlobalStyled"
import { ThemeProvider } from "styled-components"
import { theme } from "theme/ThemeUI"
import { Auth0Provider } from "@auth0/auth0-react"
import useAuth0Credentials from "overrides/theme/entel/base/hooks/v4/useAuth0Credentials"

const AppContainer = () => {
  const { clientId, domain } = useAuth0Credentials()

  const SimpleProv = () => (
    <AuthProvider>
      <ThemeProvider theme={theme.default}>
        <GlobalStyled />
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  )

  const Auth0Prov = () => (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
      }}
    >
      <SimpleProv />
    </Auth0Provider>
  )

  return <Auth0Prov />
}

export default AppContainer
