import { Fragment, FunctionComponent, useState, useEffect, useRef, useContext, useCallback } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import IPage from "interfaces/IPage"
import Paginate from "overrides/theme/entel/base/components/Commons/Pagination/Paginate"
import ErrorHandled from "overrides/theme/entel/base/components/Commons/Error/ErrorHandler"
import SortOrder from "overrides/theme/entel/base/components/Core/Order/Sort/SortOrder"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import OrderList from "overrides/theme/entel/base/components/Core/Order/OrderList"
// import { useGetSgsByStatus } from "overrides/theme/entel/base/hooks"
import { orderScreenStyle } from "overrides/theme/entel/base/pages/OrderScreens/OrderScreen.style"
import { getStatusTranslateValue } from "overrides/theme/entel/base/helpers/status_helper"
import useOrderList from "overrides/theme/entel/base/components/Core/Order/hooks/UseChekedOrderList"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import { STORAGE_SORT_ORDER } from "helpers/constHelper"
import { SortOrderEnum } from "enums/OrderEnum"
import useLocalStorage from "hooks/useLocalStorage"
import { useTranslation } from "react-i18next"
import { IoGrid, IoList } from "react-icons/io5"
import { useEnv } from "hooks"
import { formatDate } from "helpers/FormatDateHelper"
import useScheduleResources from "overrides/theme/entel/base/hooks/v4/useScheduleResources"
import { AuthContext } from "context/context"
import logo_omnix from "assets/images/logo-omnix3.png"
import { IconIoStorefront } from "theme/common/Icon.styled"
import { OptionsFilterEnum } from "overrides/theme/entel/base/enums/OptionsFilterEnum"
import { PiArrowsLeftRightBold } from "react-icons/pi"
import UseCheckedPrePickingList from "overrides/theme/entel/base/components/Core/Order/hooks/UseCheckedPrePickingList"
import { FaCircle } from "react-icons/fa6"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"

const OrderScreen: FunctionComponent<IPage & RouteComponentProps<any>> = (props: any) => {
  // TODO: refactor
  const {
    state: { user },
  } = useContext(AuthContext)
  const [sort, set_sort] = useLocalStorage(STORAGE_SORT_ORDER, SortOrderEnum.desc)
  const { t } = useTranslation()
  const { permissions } = useEnv()
  const [view, set_view] = useState("list")
  const [delivery_type, set_delivery_type] = useState("ALL")
  const [prepicking_mode, set_prepicking_mode] = useState<boolean>(false)
  const [deliveries, set_deliveries] = useState([])
  const [loading_deliveries, set_loading_deliveries] = useState(true)
  const { query, optionFilter, shippingtype, type }: any = useParams()
  const history = useHistory()
  const [data, set_data] = useState<any>(null)
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(true)
  const refElemOrders = useRef<HTMLDivElement>(null)
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { post_list_schedule_action } = useScheduleResources()
  const formatString = "dd MMM yyyy"

  const [dimensions, setDimensions] = useState({
    height: window.screen.availHeight,
    width: window.screen.availWidth,
  })

  const { post_middleware_query_action: post_middleware_query_action_osrm } = usePostMiddlewareOsrm()

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window?.innerWidth, height: window?.innerHeight })
    }
    window?.addEventListener("resize", handleResize)
    return () => {
      window?.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    set_loading_deliveries(true)
    const post_delivery_types = async () => {
      const res: any = await post_list_schedule_action({
        skipRecords: 0,
        maxRecords: 100,
        project: ["id", "deliveryType"],
      })
      set_deliveries(res?.data?.message?.records || [])
      set_loading_deliveries(false)
    }
    post_delivery_types()
    //eslint-disable-next-line
  }, [])

  // const { sg_group_by, get_sg_list_grouped_by_date } = useGetSgsByStatus()
  const post_request = async () => {
    set_loading(true)

    let body = {
      from: page * 10,
      size: 10,
      sortBy: { orderCreationDate: sort },
      filters: {
        shippingType: shippingtype,
        "_omnixStateMachine.currentState.state.name": stateParamsUppercase,
      },
    }

    if (delivery_type) {
      Object.assign(body.filters, delivery_type !== "ALL" ? { deliveryType: delivery_type } : {})
    }

    if (Boolean(user?.currentSources?.length)) {
      Object.assign(body.filters, user?.currentSources?.includes("ALL") ? {} : { "source.id": user?.currentSources })
    }

    if (optionFilter && query) {
      const key_to_filter =
        optionFilter !== OptionsFilterEnum.query ? optionFilter : query.includes("SG") ? "id" : "orderId"

      Object.assign(body.filters, { [key_to_filter]: query })
    }

    let res: any = await post_middleware_query_action_osrm("/search/shipping_groups", body)

    const records = res?.data?.message?.records

    const groupedRecords = Object.entries(
      records.reduce((acc: any, record: any) => {
        const dateParsed = formatDate(record?.orderCreationDate, formatString)

        if (!acc[dateParsed]) {
          acc[dateParsed] = []
        }
        acc[dateParsed].push(record)

        return acc
      }, {}),
    ).reduce((acc: any, [key, value]: any) => {
      acc[key] = value
      return acc
    }, {})

    // const res: any = await get_sg_list_grouped_by_date(page, sort)
    // const rest_data: any = sg_group_by(res.data.message.records)
    set_pages(res.data.message.pages)
    // set_data(rest_data)
    set_data(groupedRecords)
    set_loading(false)
  }

  const {
    checkAllOrders,
    setOrdersSGChecked,
    ordersSGChecked,
    selectAll,
    canSelectMultiple,
    statusSG,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked,
    labelGroupAction,
    setOrdersSGCheckedToPrint,
  } = useOrderList({ data, request: post_request })

  const { sgs_checked_to_prepicking, loading_prepicking_action, handle_sg_to_prepicking } = UseCheckedPrePickingList({
    data,
    request: post_request,
  })

  let shippingTypeParamsUppercase = shippingtype.toUpperCase()
  let stateParamsUppercase = type.toUpperCase()

  useEffect(() => {
    displayHeaderRouteName.dispatch(
      getStatusTranslateValue(stateParamsUppercase, shippingTypeParamsUppercase)?.subTitle,
    )
    //eslint-disable-next-line
  }, [type])

  useEffect(() => {
    post_request()
    //eslint-disable-next-line
  }, [query, optionFilter, sort, page, delivery_type])

  useEffect(() => {
    if (view === "grid" && prepicking_mode) {
      set_prepicking_mode(false)
    }
    if (dimensions.width < 1280 && prepicking_mode) {
      set_prepicking_mode(false)
    }
    //eslint-disable-next-line
  }, [dimensions, view, prepicking_mode])

  const event_set_page = (pageSeleted: number) => {
    set_page(pageSeleted)
  }

  const event_set_search = (e: any) => {
    if (!e.query) history.push(`/order/${shippingtype}/${type}`)
    if (!!e.query) history.push(`/search/order/${shippingtype}/${type}/${e.optionFilter}/${e.query}`)
  }

  const event_sort_date = (e: any) => {
    set_sort(e)
    set_page(0)
  }

  const event_set_view = (type_view: any) => {
    if (view !== type_view) set_view(type_view)
  }

  const handleClickSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = ev.target
    checkAllOrders(checked)
  }

  const handleClickUpdateOrders = () => {
    updateShippingGroupAction()
  }

  const handleClickCheckOrder = useCallback(
    (ev: any, infoSgToPrint: any) => {
      const { id, checked } = ev.target
      if (!checked) {
        setOrdersSGChecked((prevOrderSG) => {
          return prevOrderSG.filter((sg) => sg !== id)
        })
        setOrdersSGCheckedToPrint((prevValue) => prevValue.filter((sg) => sg.id !== id))
        return
      }
      setOrdersSGChecked((prevOrderSG) => [...prevOrderSG, id])
      setOrdersSGCheckedToPrint((prevValue) => [...prevValue, infoSgToPrint])
    },
    [setOrdersSGChecked, setOrdersSGCheckedToPrint],
  )

  return (
    <Fragment>
      <div className="order-screen__container" ref={refElemOrders}>
        {permissions.get_permission("global", "block-search-list") && (
          <SearchForm onClickSearch={event_set_search} isLoading={loading || loading_deliveries} backButton={true} />
        )}
        <section
          className={
            canSelectMultiple // || Boolean(sgs_checked_to_prepicking.length)
              ? "order-screen__section-filters order-screen__section-filters_check_enabled"
              : "order-screen__section-filters"
          }
        >
          {/* {Boolean(sgs_checked_to_prepicking.length) && (
            <div className="flex flex-row items-end justify-end px-4">
              <button
                className="order-screen__content__header__options__button"
                onClick={() => apply_prepicking_action()}
              >
                <p>{`Aplicar pre-picking en órdenes (${sgs_checked_to_prepicking?.length})`}</p>
              </button>
            </div>
          )} */}
          {canSelectMultiple && (
            <div className="order-screen__content-dispatch-action">
              <label className="order-screen__check-container">
                Seleccionar todo
                <input
                  type="checkbox"
                  id="selectAll"
                  name="selectAll"
                  onChange={handleClickSelectAll}
                  checked={selectAll}
                />
                <span className="order-screen__check-checkmark"></span>
              </label>
              <button
                disabled={disabledUpdateSG || loadingUpdateSG}
                onClick={handleClickUpdateOrders}
                className="order-screen__button-dispatch-action"
              >
                <p>{loadingUpdateSG ? "Espere.." : labelGroupAction}</p>
              </button>
              {loadingUpdateSG && <Loading defaultOpened={loadingUpdateSG} />}
            </div>
          )}
          <div className="order-screen__container__header__options">
            <div className="order-screen__content__header__options">
              <div className="order-screen__content__header__options__item">
                <p>Tipo de entrega: </p>
                <select
                  className="order-screen__content__header__options_bordered-select"
                  value={delivery_type}
                  onChange={(e: any) => set_delivery_type(e.target.value)}
                >
                  <option value="ALL">Todos</option>
                  {deliveries.map((delivery: any, i: number) => (
                    <option key={i} value={delivery.id}>
                      {delivery?.deliveryType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="order-screen__content__header__options__item" onClick={() => event_set_view("grid")}>
                <p>{t("Vista Resumen")}</p> <IoGrid />
              </div>
              <div className="order-screen__content__header__options__item" onClick={() => event_set_view("list")}>
                <p>{t("Vista Lista")}</p> <IoList />
              </div>
              {view === "list" && type.toString().toUpperCase() === "ASSIGNED" ? (
                <button
                  className="order-screen__content__header__options__button"
                  onClick={() => set_prepicking_mode(!prepicking_mode)}
                >
                  <p>{prepicking_mode ? t("Deshabilitar modo pre-picking") : t("Habilitar modo pre-picking")}</p>
                </button>
              ) : null}
            </div>
            <SortOrder handleChangeSort={event_sort_date} disabled={loading || loading_deliveries} />
          </div>
        </section>

        {loading || loading_deliveries || loading_prepicking_action ? (
          <Loading defaultOpened={loading || loading_deliveries || loading_prepicking_action} />
        ) : (
          <>
            {Object.keys(data || {}).length ? (
              <div className="order-screen__order-list-container--main">
                <div className="order-screen__order-list-container">
                  <OrderList
                    ordersGroup={data}
                    currentDisplayList={view}
                    handleClickCheckOrder={handleClickCheckOrder}
                    canSelectMultiple={canSelectMultiple}
                    conditionChecked={conditionChecked}
                    ordersSGChecked={ordersSGChecked}
                    statusSG={statusSG}
                    prepicking_mode={prepicking_mode}
                    sgs_checked_to_prepicking={sgs_checked_to_prepicking}
                    handle_sg_to_prepicking={handle_sg_to_prepicking}
                  />
                </div>
                {view === "list" ? (
                  <section className="order-screen__section-icons-ind">
                    <div className="order-screen__section-icons-ind__icon_desc">
                      <IconIoStorefront style={{ color: "#002eff", fontSize: "1.2rem", margin: "0 0.5rem" }} />
                      <p>Despacho a domicilio</p>
                    </div>
                    <div className="order-screen__section-icons-ind__icon_desc">
                      <PiArrowsLeftRightBold style={{ color: "#002eff", fontSize: "1.2rem", margin: "0 0.5rem" }} />
                      <p>Portabilidad</p>
                    </div>
                    <div className="order-screen__section-icons-ind__icon_desc">
                      <FaCircle style={{ color: "#008f39", fontSize: "1.2rem", margin: "0 0.5rem" }} />
                      <p>Orden cerrada en caja</p>
                    </div>
                    <div className="order-screen__section-icons-ind__icon_desc">
                      <FaCircle style={{ color: "#ff2c2c", fontSize: "1.2rem", margin: "0 0.5rem" }} />
                      <p>Orden pendiente de cierre en caja</p>
                    </div>
                  </section>
                ) : null}
                {pages > 1 && (
                  <div className="order-screen__paginate-container">
                    <Paginate
                      pageCount={pages}
                      onChangePage={event_set_page}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  </div>
                )}
                <div className="cont__order-list__footer">
                  <p>Powered by</p>
                  <img src={logo_omnix} alt="logo" className="logo_omnix" />
                </div>
              </div>
            ) : (
              <ErrorHandled orders={data} message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </div>
      <style jsx>{orderScreenStyle}</style>
    </Fragment>
  )
}

export default OrderScreen
