import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  OrderDetailModalActionStyle,
  FormCancelActionStyle,
} from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetailModalAction.style"
import { useForm } from "react-hook-form"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { useParams } from "react-router-dom"
import get_cancel_reasons from "overrides/theme/entel/base/helpers/cancel_reasons"

const FormCancelAction = ({ close, action, status, confirmAction, cancel_reasons, set_reason_cancel }: any) => {
  const { t } = useTranslation()
  const { register, watch } = useForm<any>()
  const cancel_reason = watch("reason")

  useEffect(() => {
    cancel_reason && set_reason_cancel(cancel_reason)
    // eslint-disable-next-line
  }, [cancel_reason])

  const is_there_cancel_options = Boolean(cancel_reasons?.length)

  return (
    <>
      <form className="form_cancel_action__main">
        {is_there_cancel_options ? (
          <select {...register("reason")} className="form_cancel_action__input_form form_cancel_action__select_form">
            <option value="" selected disabled>
              Seleccione motivo de cancelación...
            </option>
            {cancel_reasons.map((reason: any, i: number) => (
              <option key={i} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </select>
        ) : null}
        <div className="order_detail_modal_action__cont_buttons">
          <button type="button" className="order_detail_modal_action__btn_no" onClick={close}>
            No
          </button>
          <button
            disabled={Boolean(!cancel_reason)}
            className="order_detail_modal_action__btn_yes"
            type="button"
            onClick={() => {
              confirmAction()
              close()
            }}
          >
            {`Si, ${
              status === "IN_TRANSIT" && action.action === "cancel" ? "Retornar y cancelar" : t(`${action.action}`)
            } Orden`}
          </button>
        </div>
      </form>
      <style jsx>{FormCancelActionStyle}</style>
    </>
  )
}

const OrderDetailModalAction = ({
  action,
  status,
  confirmAction,
  close,
  set_reason_cancel,
  items_selialized_completed,
}: any) => {
  const { t } = useTranslation()
  const isCancel = action.action === "cancel"
  const isPick = action.action === "pick"
  const _translates: any = translatesObj
  const { shippingtype }: any = useParams()
  const cancel_reasons = get_cancel_reasons(status, shippingtype)

  return (
    <>
      <div className="order_detail_modal_action__root">
        <div className="order_detail_modal_action__main_cont">
          <div className="order_detail_modal_action__cont_title">
            <p className="order_detail_modal_action__cont_title_main">
              ¿Desea aplicar{" "}
              <span>
                "
                {status === "IN_TRANSIT" && action.action === "cancel"
                  ? "Retornar y cancelar"
                  : t(_translates[action.action])}
                "
              </span>{" "}
              a esta orden?
            </p>
            <p className="order_detail_modal_action__cont_title_descr">Esta acción NO se puede deshacer</p>
          </div>
          <div className="order_detail_modal_action__cont_body">
            {isCancel && (
              <FormCancelAction
                action={action}
                close={close}
                confirmAction={confirmAction}
                cancel_reasons={cancel_reasons}
                set_reason_cancel={set_reason_cancel}
                status={status}
              />
            )}
          </div>
          {!isCancel && (
            <div className="order_detail_modal_action__cont_buttons">
              <button className="order_detail_modal_action__btn_no" onClick={close}>
                No
              </button>
              <button
                disabled={Boolean(isPick ? !items_selialized_completed : false)}
                className="order_detail_modal_action__btn_yes"
                onClick={() => {
                  confirmAction()
                  close()
                }}
              >
                {`Si, aplicar ${t(_translates[action.action])}`}
              </button>
            </div>
          )}
        </div>
      </div>
      <style jsx>{OrderDetailModalActionStyle}</style>
    </>
  )
}

export default OrderDetailModalAction
